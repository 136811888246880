import React from 'react';

interface MediaElementProps {
  src: string;
  alt: string;
  className?: string;
  index?: number;
  onClick?: (index: number) => void;
}

export function isVideoSrc(src: string): boolean {
  return /\.(mp4|webm)$/.test(src);
}

export function MediaElement({
  src,
  alt,
  className,
  index,
  onClick,
}: MediaElementProps) {
  if (isVideoSrc(src)) {
    const videoType = src.endsWith('.mp4') ? 'video/mp4' : 'video/webm';

    return (
      <video
        onClick={onClick ? () => onClick(index) : null}
        controls={onClick ? null : true}
        className={className ? className + ' cmp-photogrid__photo-media-video': null}
      >
        <source src={src} type={videoType} />
        Sorry, your browser does not support embedded videos.
      </video>
    );
  }

  return (
    <img
      src={src}
      alt={alt ? alt : null}
      className={
        className ? className + ' cmp-photogrid__photo-media-img' : null
      }
      onClick={onClick ? () => onClick(index) : null}
    />
  );
}
