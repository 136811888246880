import React, {useEffect, useMemo, useRef, useState} from 'react';
import Lightbox from 'lightbox-react';

import {useFilter} from '../pressreleaselisting/useFilter';
import {Icon} from '../pressreleaselisting/Icon';
import {displayCategoryString, Media} from './Media';
import {PhotoData} from './photogrid.site';
import {MediaElement} from './MediaElement';

interface FilterInternationalization {
  showingOfString: string;
  clearLabel: string;
  categoryLabel: string;
  categoryArchitecturalCoatingsLabel: string;
  categoryCorporateLabel: string;
  categoryFinancialLabel: string;
  categoryInnovationLabel: string;
  categoryIndustrialLabel: string;
  categorySustainabilityLabel: string;
  categoryEmployeeLabel: string;
  categoryCultureLabel: string;
  categoryManufacturingAndDistributionLabel: string;
  categoryIndustrialCoatingsLabel: string;
  categoryLeadershipLabel: string;
  categoryStoresLabel: string;
  categoryInclusionDiversityEquityLabel: string;
  categoryCommunityEngagementLabel: string;
  categoryBuildingOurFutureLabel: string;
}

interface PhotoGridProps {
  photoGridPhotos: PhotoData[];
  spritePath: string;
  filterInternationalization?: FilterInternationalization;
}

function shouldShowPhoto(category: string, photo: PhotoData): boolean {
  return category === null || category === photo.category;
}

function createLightboxContent(
  spritePath: string,
  {src, alt, category}: PhotoData
): string | React.ReactElement {
  return (
    <div className="cmp-photogrid__lightbox-container">
      <MediaElement
        src={src}
        alt={alt}
        className="cmp-photogrid__lightbox-media"
      />
      <div className="cmp-photogrid__lightbox-metadata">
        <div className="cmp-photogrid__lightbox-category">
          {displayCategoryString(category)}
        </div>
        {alt && (
          <div className="cmp-photogrid__lightbox-description">{alt}</div>
        )}
        <div className="cmp-photogrid__lightbox-download">
          <a
            className="cmp-photogrid__photo-card-download-btn"
            href={src}
            download
          >
            Download{' '}
            <Icon
              iconName={'download-circle'}
              spritePath={spritePath}
              iconClassName={'cmp-photogrid__photo-card-icon'}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export function PhotoGrid({
  photoGridPhotos,
  spritePath,
  filterInternationalization = {
    showingOfString: 'Showing {current} of {total}',
    clearLabel: 'Clear',
    categoryLabel: 'Category',
    categoryCorporateLabel: 'Corporate',
    categoryArchitecturalCoatingsLabel: 'Architectural Coatings',
    categoryFinancialLabel: 'Financial',
    categoryInnovationLabel: 'Innovation',
    categoryIndustrialLabel: 'Industrial',
    categorySustainabilityLabel: 'Sustainability',
    categoryEmployeeLabel: 'Employee',
    categoryCultureLabel: 'Culture',
    categoryManufacturingAndDistributionLabel: 'Manufacturing & Distribution',
    categoryIndustrialCoatingsLabel: 'Industrial Coatings',
    categoryLeadershipLabel: 'Leadership',
    categoryStoresLabel: 'Stores',
    categoryInclusionDiversityEquityLabel: 'Inclusion, Diversity & Equity',
    categoryCommunityEngagementLabel: 'Community & Engagement',
    categoryBuildingOurFutureLabel: 'Building Our Future',
  },
}: PhotoGridProps) {
  const [_searchText, _setSearchText, category, setCategory, _year, _setYear] =
    useFilter();
  const [filteredPhotoGridPhotos, setFilteredPhotoGridPhotos] = useState<
    PhotoData[]
  >([]);
  const [mediaIndex, setMediaIndex] = useState(0);
  const [isLightboxOpen, setIsLightboxOpen] = useState(false);
  const [filteredLightboxPhotos, setFilteredLightboxPhotos] = useState([]);

  function filterPhotoGrid(): PhotoData[] {
    return photoGridPhotos.filter((photo) => shouldShowPhoto(category, photo));
  }

  useEffect(() => {
    setFilteredPhotoGridPhotos(filterPhotoGrid());
  }, []);

  useEffect(() => {
    const filteredMediaData = filterPhotoGrid();

    setFilteredPhotoGridPhotos(filteredMediaData);
    setFilteredLightboxPhotos(
      filteredMediaData.map((photoData) =>
        createLightboxContent(spritePath, photoData)
      )
    );
  }, [photoGridPhotos, category]);

  function updateCategory(event): void {
    const eventValue = event.target.value;

    setCategory(eventValue === 'none' ? null : eventValue);
  }

  function resetFilter(): void {
    setCategory(null);
  }

  function onMediaClick(index: number): void {
    setMediaIndex(index);
    setIsLightboxOpen(true);
  }

  const photoGridHtml = useMemo(() => {
    return filteredPhotoGridPhotos.map((photo, i) => (
      <Media
        key={i}
        src={photo.src}
        alt={photo.alt}
        category={photo.category}
        spritePath={spritePath}
        index={i}
        onClick={onMediaClick}
      />
    ));
  }, [filteredPhotoGridPhotos, spritePath, category]);

  return (
    <div className="cmp-photogrid__container">
      <div className="cmp-photogrid__number-shown">
        {filterInternationalization.showingOfString
          .replace('{current}', filteredPhotoGridPhotos.length.toString())
          .replace('{total}', photoGridPhotos.length.toString())}
      </div>
      <div className="cmp-photogrid__content">
        <div className="cmp-photogrid__filter">
          <div className="cmp-photogrid__filter-category-container">
            <select
              className="cmp-photogrid__filter-category"
              onChange={updateCategory}
              value={category === null ? 'none' : category}
              aria-label={filterInternationalization.categoryLabel}
            >
              <option value="none">
                {filterInternationalization.categoryLabel}
              </option>
              <option value="corporate">
                {filterInternationalization.categoryCorporateLabel}
              </option>
              <option value="architectural-coatings">
                {filterInternationalization.categoryArchitecturalCoatingsLabel}
              </option>
              <option value="financial">
                {filterInternationalization.categoryFinancialLabel}
              </option>
              <option value="innovation">
                {filterInternationalization.categoryInnovationLabel}
              </option>
              <option value="industrial">
                {filterInternationalization.categoryIndustrialLabel}
              </option>
              <option value="sustainability">
                {filterInternationalization.categorySustainabilityLabel}
              </option>
              <option value="employee">
                {filterInternationalization.categoryEmployeeLabel}
              </option>
              <option value="culture">
                {filterInternationalization.categoryCultureLabel}
              </option>
              <option value="manufacturing-&-distribution">
                {
                  filterInternationalization.categoryManufacturingAndDistributionLabel
                }
              </option>
              <option value="industrial-coatings">
                {filterInternationalization.categoryIndustrialCoatingsLabel}
              </option>
              <option value="leadership">
                {filterInternationalization.categoryLeadershipLabel}
              </option>
              <option value="stores">
                {filterInternationalization.categoryStoresLabel}
              </option>
              <option value="inclusion,-diversity-&-equity">
                {
                  filterInternationalization.categoryInclusionDiversityEquityLabel
                }
              </option>
              <option value="community-engagement">
                {filterInternationalization.categoryCommunityEngagementLabel}
              </option>
              <option value="building-our-future">
                {filterInternationalization.categoryBuildingOurFutureLabel}
              </option>
            </select>
            <Icon
              spritePath={spritePath}
              iconName={'filter-dropdown-arrow'}
              iconClassName={'cmp-photogrid__filter-form-arrow'}
            />
          </div>
          <button className="cmp-photogrid__filter-clear" onClick={resetFilter}>
            {filterInternationalization.clearLabel}
          </button>
        </div>
        <div className="cmp-photogrid__photos">{photoGridHtml}</div>
      </div>
      {isLightboxOpen && (
        <Lightbox
          mainSrc={filteredLightboxPhotos[mediaIndex]}
          nextSrc={
            filteredLightboxPhotos[
              (mediaIndex + 1) % filteredLightboxPhotos.length
            ]
          }
          prevSrc={
            filteredLightboxPhotos[
              (mediaIndex + filteredLightboxPhotos.length - 1) %
                filteredLightboxPhotos.length
            ]
          }
          onMovePrevRequest={() =>
            setMediaIndex(
              (mediaIndex + filteredLightboxPhotos.length - 1) %
                filteredLightboxPhotos.length
            )
          }
          onMoveNextRequest={() =>
            setMediaIndex((mediaIndex + 1) % filteredLightboxPhotos.length)
          }
          onCloseRequest={() => setIsLightboxOpen(false)}
          enableZoom={false}
        />
      )}
    </div>
  );
}
