import {useState} from 'react';

export interface FilterStartingValues {
  searchText?: string;
  category?: string;
  year?: number;
}

export function useFilter(startingValues?: FilterStartingValues) {
  const [searchText, setSearchText] = useState<string>(
    startingValues?.searchText !== undefined ? startingValues.searchText : ''
  );
  const [category, setCategory] = useState<string | null>(
    startingValues?.category !== undefined ? startingValues.category : null
  );
  const [year, setYear] = useState<number | null>(
    startingValues?.year !== undefined ? startingValues.year : null
  );

  return [searchText, setSearchText, category, setCategory, year, setYear];
}
