import React, {useCallback} from 'react';

import {Icon} from '../pressreleaselisting/Icon';
import {MediaElement} from './MediaElement';

interface PhotoProps {
  src: string;
  alt: string;
  category: string;
  spritePath: string;
  index: number;
  onClick: (index: number) => void;
}

export function displayCategoryString(category: string): string {
  switch (category) {
    case 'architectural-coatings':
      return 'Architectural Coatings';
    case 'financial':
      return 'Financial';
    case 'innovation':
      return 'Innovation';
    case 'industrial':
      return 'Industrial';
    case 'sustainability':
      return 'Sustainability';
    case 'employee':
      return 'Employee';
    case 'culture':
      return 'Culture';
    case 'manufacturing-&-distribution':
      return 'Manufacturing & Distribution';
    case 'industrial-coatings':
      return 'Industrial Coatings';
    case 'leadership':
      return 'Leadership';
    case 'stores':
      return 'Stores';
    case 'inclusion,-diversity-&-equity':
      return 'Inclusion, Diversity & Equity';
    case 'community-engagement':
      return 'Community Engagement';
    case 'building-our-future':
      return 'Building Our Future';
    default:
      return 'Corporate';
  }
}

function MediaComponent({
  src,
  alt,
  category,
  spritePath,
  index,
  onClick,
}: PhotoProps) {
  const onClickCallback = useCallback(() => onClick(index), [index]);

  return (
    <div className="cmp-photogrid__photo-container">
      <div className="cmp-photogrid__photo">
        <MediaElement
          src={src}
          alt={alt}
          index={index}
          onClick={onClickCallback}
          className={'cmp-photogrid__photo-media'}
        />
      </div>
      <div className="cmp-photogrid__photo-card">
        <div className="cmp-photogrid__photo-card-category">
          {displayCategoryString(category)}
        </div>
        <div className="cmp-photogrid__photo-card-view-download">
          <button className="cmp-photogrid__photo-card-view-btn" type="button" onClick={onClickCallback}>
            View{' '}
            <Icon
              spritePath={spritePath}
              iconName={'filter-arrow-right'}
              iconClassName={'cmp-photogrid__photo-card-icon'}
            />
          </button>
          <a
            className="cmp-photogrid__photo-card-download-btn"
            href={src}
            download
          >
            Download{' '}
            <Icon
              iconName={'download-circle'}
              spritePath={spritePath}
              iconClassName={'cmp-photogrid__photo-card-icon'}
            />
          </a>
        </div>
      </div>
    </div>
  );
}

export const Media = React.memo(MediaComponent);
