import React from 'react';
import ReactDOM from 'react-dom';
import {PhotoGrid} from './PhotoGrid';

export interface PhotoData {
  src: string;
  alt: string;
  category: string;
}

const photoGridJsonElem = document.getElementById('photoGridJsonOutput');
const photoGridReactElem = document.getElementById('photoGridReact');

if (photoGridJsonElem === null || photoGridReactElem === null) {
  throw new Error('No Photo Grid JSON element or React element not found.');
}

let photoGridJson: PhotoData[] = JSON.parse(photoGridJsonElem.innerHTML);

const spritePath = photoGridReactElem.dataset.spriteSheetPath;

ReactDOM.render(
  <React.StrictMode>
    <PhotoGrid photoGridPhotos={photoGridJson} spritePath={spritePath} />
  </React.StrictMode>,
  photoGridReactElem
);
