import React from 'react';

interface IconProps {
  spritePath: string;
  iconName: string;
  iconClassName?: string | null;
}

export function Icon({spritePath, iconName, iconClassName = null}: IconProps) {
  return (
    <svg className={iconClassName}>
      <use xlinkHref={`${spritePath}#${iconName}`} />
    </svg>
  );
}
